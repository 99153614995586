function checksize(){
  $('body').removeAttr('style');
  if($('body').width() < 768){
    is_mobile = true;
    is_tablette = false;
  } else if($('body').width() < 1200){
    is_tablette = true;
    is_mobile = false;
  } else {
    is_mobile = false;
    is_tablette = false;
    if($('.wc').height() > $('body').height() ){
      $('body').height($('.wc').height());
    }
  }
}
