var is_mobile = false;
var is_tablette = false;

jQuery(document).ready(function ($) {
  checksize();
  bindmenu();

  if ($("#svg").length > 0) {
    var maxdonught = 282;

    // 282 = 100%

    var nbtotal = parseInt($("#nbtotal").val());
    var nbfinished = parseInt($("#nbfinished").val());
    var temppercentage = (nbfinished * 100) / nbtotal;

    temppercentage = (temppercentage * maxdonught) / 100;

    var tempdebutstroke = temppercentage;
    var tempfinstroke = 282 - temppercentage;

    $("#colorstrike").attr(
      "stroke-dasharray",
      tempdebutstroke + "," + tempfinstroke
    );
  }

  window.onresize = function () {
    checksize();
  };

  if ($(".tr_50").length > 0) {
    $("h3")
      .off()
      .on("click", function () {
        var target = $(this).attr("data-click");
        $(".tr_" + target).slideToggle(250);

        $(this).toggleClass("closed");
      });
  }
  if ($(".trsingle__link").length > 0) {
    $(".trsingle__link")
      .off()
      .on("click", function () {
        var target = $(this).attr("data-href");
        if ($("select").val()) {
          var ajoutlang = $("select").val();
          var testlang = $("select option:selected").attr("data-id");
          if (testlang == "i18n") {
            ajoutlang = "i18n/index.html?lang=" + ajoutlang;
          }
        } else {
          ajoutlang = "i18n/index.html?lang=en";
        }
        // window.location = target+ajoutlang;
        window.open(
          window.location.origin + "/" + target + ajoutlang,
          "_blank"
        );
      });
  }

  $(".myvideos__box")
    .off()
    .on("mouseleave", function () {
      var myid = $(this).find("iframe").attr("id");
      // console.log($('#'+myid).contents().find('.html5-video-player').length);
      // console.log(myid);
      if (
        $("#" + myid)
          .contents()
          .find(".html5-video-player")
          .hasClass("playing-mode")
      ) {
        $(this).find(".myvideos__box__titre, .myvideos__box__filtre").hide();
      } else {
        $(this).find(".myvideos__box__titre, .myvideos__box__filtre").show();
      }
    });

  $("select").on("change", function () {
    $.post(
      "ajax_call_video.php",
      {
        func: "callvids",
        year: $(".yearselect").val(),
        businessline: $(".businessselect").val(),
        type: $(".typeselect").val(),
      },
      function (data) {
        console.log(data);
        $(".wrappervideos").html("");
        var numvideo = 1;
        for (var i in data) {
          // console.log(data[i].url);

          $(".wrappervideos").append(
            '<div class="myvideos__box myvideos__box25 myvideos__box25-first"><iframe id="video' +
              numvideo +
              '" class="videoiframe" style="width: 100%; height: 100%;" src="' +
              data[i].url +
              '?autoplay=0&showinfo=0&controls=1" frameborder="0" allowfullscreen></iframe><div class="myvideos__box__titre">' +
              data[i].title +
              "</div></div>"
          );

          numvideo++;
        }
      },
      "json"
    );
  });

  if ($("#myavatarformodule").length > 0) {
    localStorage.setItem("avatar_cotecna", $("#myavatarformodule").attr("src"));
  }

  /*  if($('.yearselect').length > 0){
    $('.myvideos__box').hide();
    var annee = parseInt($('.yearselect option:eq(1)').val());
    // console.log(annee);
    $('.myvideos__box[data-annee="'+annee+'"]').show();

    $('.yearselect').off().on('change', function(){
      var annee2 = parseInt($('.yearselect').val());
      if(annee2 != 0){
        $('.myvideos__box').hide();
        $('.myvideos__box[data-annee="'+annee2+'"]').show();
      }
    });
  }*/

  if ($(".catselect").length > 0) {
    $(".mycat_boxa").hide();
    var cat = $(".catselect option:eq(2)").val();
    // console.log(cat);
    $('.mycat_boxa[data-cat="' + cat + '"]').show();
    // console.log($('.catselect option.selected').length);
    if ($(".catselect option.selected").length == 0) {
      $(".catselect option:eq(2)").prop("selected", true);
    }
    $(".catselect").trigger("change");

    $(".catselect")
      .off()
      .on("change", function () {
        var cat2 = $(".catselect").val();
        if (cat2 != "") {
          $(".mycat_boxa").hide();
          $('.mycat_boxa[data-cat="' + cat2 + '"]').show();
        }
      });
  }
});
