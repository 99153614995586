function bindmenu(){
  $('.wc__header__burger').on('click', function(){
    checksize();
    if($(this).hasClass('open')){
      $(this).removeClass('open');
      $('.menu, .wc__main').removeClass('open');

      if(is_mobile){
        $('.wc__main > div').not('.menu').show();
      }
    } else {
      $(this).addClass('open');
      $('.menu, .wc__main').addClass('open');

      if(is_mobile){
        $('.wc__main > div').not('.menu').hide();
      }
    }

  });
}
