jQuery(document).ready(function($){

  $('input[type="file"]').change(function(e){
      var fileName = e.target.files[0].name;
      $(this).next('label').html("<span>"+fileName+"</span>");
      $('#form-image').show();
      // $(this).next('label').html("fileName");
  });


  $( ".avatar-list-image" ).click(function() {
    $(".avatar-list-image").removeClass('selected');
    $(this).addClass('selected');
    var index = $(this).attr('src');
    var fic = index.split('?fic=');
    $( "input[name='avatar-choice']" ).attr('value',fic[1]);
    // alert(index);

    $('#form-avatar-button').show();

  });
});
